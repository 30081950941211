<template>
  <div class="page_container">
    <div class="container">
      <el-card shadow="always">
        <div class="content_title">
          <div class="content_title_left">
            <div>
              <img class="content_title_left_img" :src="headDataFrom.coverImage" />
            </div>
            <div class="content_title_left_content">
              <div class="content_title_left_title">{{ headDataFrom.informationName }}</div>
              <div class="content_title_left_body">
                <div
                  class="content_title_left_label"
                  v-for="(item, index) in headDataFrom.labels"
                  :key="index"
                >
                  <div v-if="index < 7">
                    <span v-if="item">#</span>
                    <span>{{ item }}</span>
                  </div>
                  <div v-if="index == 7">
                    <span v-if="item">#</span>
                    <el-popover placement="right" width="600" trigger="hover">
                      <div
                        class="hidden_label_box"
                        style="
                          width: 100%;
                          display: flex;
                          flex-direction: row;
                          align-content: center;
                          flex-wrap: wrap;
                        "
                      >
                        <span
                          class="office"
                          style="
                            border: 1px solid #559eff;
                            color: #4e93fb;
                            box-sizing: border-box;
                            padding: 1px 6px;
                            display: block;
                            font-size: 14px;
                            font-weight: 400;
                            border-radius: 4px;
                            margin-right: 10px;
                            margin-bottom: 10px;
                          "
                          v-for="(o, index) in headDataFrom.labels"
                          :key="index"
                          >{{ o }}</span
                        >
                      </div>
                      <span slot="reference">{{ item }}...{{ headDataFrom.labels.length }}+</span>
                    </el-popover>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content_title_right">
            <el-button @click="goAnalysis" type="primary">返回上一级</el-button>
          </div>
        </div>
      </el-card>
      <el-card class="content_center">
        <div class="center_search">
          <div>
            该内容推送以下<span style="color: #448aff">{{ total }}</span
            >家企业
          </div>
          <div class="search_right">
            <el-input
              v-model="queryInfo.queryConditions"
              placeholder="请输入企业名称"
              class="input-with-select"
              clearable
              @change="changeInput"
            >
              <el-button class="el-button" slot="append" @click="changeInputButton">搜索</el-button>
            </el-input>
          </div>
        </div>
        <div>
          <el-table
            v-loading="loading"
            element-loading-text="拼命加载中"
            :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
            :data="detailsTableData"
            style="width: 100%; background-color: #f5f7fb"
            @sort-change="changeOrders"
          >
            <el-table-column type="index" align="center" label="编号" width="80"> </el-table-column>
            <el-table-column align="center" label="logo" width="120">
              <template v-slot="{ row }">
                <img v-if="row.companyLogo" class="center_table_img" :src="row.companyLogo" />
                <img v-else class="center_table_img" src="../../../assets/image/Group 3237.png" />
              </template>
            </el-table-column>
            <el-table-column prop="companyFullName" align="center" label="企业名称">
            </el-table-column>
            <!-- <el-table-column prop="" align="center" label="所属行业">
            </el-table-column> -->
            <el-table-column prop="city" align="center" label="所属城市"> </el-table-column>
            <el-table-column align="center" label="所属行业" width="350">
              <template v-slot="{ row }">
                <div class="center_table_label" v-if="row.labelList">
                  <div
                    class="center_table_label_text"
                    v-for="(item, index) in row.labelList"
                    :key="index"
                  >
                    <span>{{ item }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column sortable prop="correlation" align="center" label="关联度">
              <template v-slot="{ row }">
                <div v-if="row.correlation" style="color: red">{{ row.correlation }}%</div>
              </template>
            </el-table-column>
            <el-table-column sortable prop="userNum" align="center" label="用户数">
              <template v-slot="{ row }">
                <el-button type="text" v-if="row.userNum > 0" @click="userNumDialog(row)">{{
                  row.userNum
                }}</el-button>
                <div v-else>0</div>
              </template>
            </el-table-column>
            <el-table-column sortable prop="viewNum" align="center" label="用户查看数">
              <template v-slot="{ row }">
                <el-button type="text" v-if="row.viewNum > 0" @click="viewNumDialog(row)">{{
                  row.viewNum
                }}</el-button>
                <div v-else>0</div>
              </template>
            </el-table-column>
            <el-table-column sortable prop="shareNum" align="center" label="用户分享数">
              <template v-slot="{ row }">
                <el-button type="text" v-if="row.shareNum > 0" @click="shareNumDialog(row)">{{
                  row.shareNum
                }}</el-button>
                <div v-else>0</div>
              </template>
            </el-table-column>
            <el-table-column sortable prop="pushTime" align="center" label="推送时间">
              <template v-slot="{ row }">
                <div>{{ row.pushTime | date }}</div>
                <div>{{ row.pushTime | time }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-card>
    </div>
    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :page-sizes="[4, 10, 30]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 用户数弹窗 -->
    <el-dialog :visible.sync="userNumDialogVisible" width="70%" center>
      <span slot="title" class="custom-dialog-title">
        <div class="left-text">用户数</div>
        <span class="center-text">{{ userNumTitle }}</span>
      </span>

      <div>
        <el-table
          :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
          :data="userNumTableData"
          style="width: 100%; background-color: #f5f7fb"
        >
          <el-table-column type="index" align="center" label="编号" width="80"> </el-table-column>
          <el-table-column prop="realName" align="center" label="姓名"> </el-table-column>
          <el-table-column align="center" label="性别">
            <template v-slot="{ row }">
              <div v-if="row.sex == 2">女</div>
              <div v-else>男</div>
            </template>
          </el-table-column>
          <el-table-column prop="position" align="center" label="职位"> </el-table-column>
          <el-table-column prop="phone" align="center" label="手机号"> </el-table-column>
          <el-table-column prop="address" align="center" label="所在城市"> </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="new_page">
          <el-pagination
            :page-sizes="[4, 10, 30]"
            :page-size="queryInfo.pageSizeSon"
            layout="total, sizes, prev, pager, next, jumper"
            :total="userNumTotal"
            @size-change="userNumHandleSizeChange"
            @current-change="userNumHandleCurrentChange"
          />
        </div>
      </span>
    </el-dialog>
    <!-- 用户查看数弹窗 -->
    <el-dialog :visible.sync="viewNumDialogVisible" width="70%" center>
      <span slot="title" class="custom-dialog-title">
        <div class="left-text">用户查看数</div>
        <span class="center-text">{{ viewNumTitle }}</span>
      </span>

      <div>
        <el-table
          :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
          :data="viewNumTableData"
          style="width: 100%; background-color: #f5f7fb"
          @sort-change="changeViewNumOrders"
        >
          <el-table-column type="index" align="center" label="编号" width="80"> </el-table-column>
          <el-table-column prop="realName" align="center" label="姓名"> </el-table-column>
          <el-table-column align="center" label="性别">
            <template v-slot="{ row }">
              <div v-if="row.sex == 2">女</div>
              <div v-else>男</div>
            </template>
          </el-table-column>
          <el-table-column prop="position" align="center" label="职位"> </el-table-column>
          <el-table-column prop="phone" align="center" label="手机号"> </el-table-column>
          <el-table-column prop="address" align="center" label="所在城市"> </el-table-column>
          <el-table-column sortable prop="checkTime" align="center" label="查看日期">
            <template v-slot="{ row }">
              <div>{{ row.checkTime | date }}</div>
              <div>{{ row.checkTime | time }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="new_page">
          <el-pagination
            :page-sizes="[4, 10, 30]"
            :page-size="queryInfo.pageSizeSon"
            layout="total, sizes, prev, pager, next, jumper"
            :total="viewNumTotal"
            @size-change="viewNumHandleSizeChange"
            @current-change="viewNumHandleCurrentChange"
          />
        </div>
      </span>
    </el-dialog>
    <!-- 用户分享数弹窗 -->
    <el-dialog :visible.sync="shareNumDialogVisible" width="70%" center>
      <span slot="title" class="custom-dialog-title">
        <div class="left-text">用户分享数</div>
        <span class="center-text">{{ shareNumTitle }}</span>
      </span>

      <div>
        <el-table
          :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
          :data="shareNumTableData"
          style="width: 100%; background-color: #f5f7fb"
          @sort-change="changeShareNumOrders"
        >
          <el-table-column type="index" align="center" label="编号" width="80"> </el-table-column>
          <el-table-column prop="realName" align="center" label="姓名"> </el-table-column>
          <el-table-column align="center" label="性别">
            <template v-slot="{ row }">
              <div v-if="row.sex == 2">女</div>
              <div v-else>男</div>
            </template>
          </el-table-column>
          <el-table-column prop="position" align="center" label="职位"> </el-table-column>
          <el-table-column prop="phone" align="center" label="手机号"> </el-table-column>
          <el-table-column prop="address" align="center" label="所在城市"> </el-table-column>
          <el-table-column sortable prop="createTime" align="center" label="分享日期">
            <template v-slot="{ row }">
              <div>{{ row.createTime | date }}</div>
              <div>{{ row.createTime | time }}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="new_page">
          <el-pagination
            :page-sizes="[4, 10, 30]"
            :page-size="queryInfo.pageSizeSon"
            layout="total, sizes, prev, pager, next, jumper"
            :total="shareNumTotal"
            @size-change="shareNumHandleSizeChange"
            @current-change="shareNumHandleCurrentChange"
          />
        </div>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import {
  selectInformationDetail,
  selectInformationAnalysisPage,
  selectInformationAnalysisUserPage,
  selectInformationAnalysisUserViewPage,
  selectInformationAnalysisUserSharePage
} from '@/api/analysis.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  companyId: '', // 企业主键
  informationId: '', // 资讯主键
  informationType: '1', // 1应用场景，2数智技术，3行业动态，4案例视频，5数智产品，6服务商,7政策解读,8组织人才,9专家观点,10数智案例,11场景知识
  orders: '1', // 企业主键
  queryConditions: '', // 模糊搜索资讯名称
  type: 1, // 1=日，2=周，3=月，4=年
  weekNum: '', //周
  time: '',
  typePage: 4, //列表；1=日，2=周，3=月，4=年
  timePage: '', //列表；头部；时间
  weekNumPage: '', //列表；列表；第多少周
  dateType: 1, // 数据类型；1=内容，2=企业，3=顾问
  pageNumSon: 1, // 弹窗当前页
  pageSizeSon: 10 // 弹窗条数
})
export default {
  name: 'contentDetails',
  data() {
    return {
      queryInfo: { ...defaultQueryInfo }, //查询参数
      loading: true,
      total: 0, // 总条数
      detailsTableData: [{}], //详情表格数据
      headDataFrom: {}, // 头部数据
      userNumDialogVisible: false, // 用户数弹窗
      viewNumDialogVisible: false, // 用户查看数弹窗
      shareNumDialogVisible: false, // 用户分享数弹窗
      userNumTableData: [{}], // 用户数列表
      userNumTitle: '', // 用户数列表标题
      userNumTotal: 0, // 用户数列表条数
      viewNumTableData: [{}], // 用户查看数列表
      viewNumTitle: '', // 用户查看数列表标题
      viewNumTotal: 0, // 用户查看数列表条数
      shareNumTableData: [{}], // 用户分享数列表
      shareNumTitle: '', // 用户分享数列表标题
      shareNumTotal: 0 // 用户分享列表条数
    }
  },
  computed: {
    archiveId() {
      //存在是编辑 不存在为新增
      return this.$route.query.archiveId
    }
  },
  created() {
    this.queryInfo.informationId = this.archiveId
    this.headSearch()
    this.dataSearch()
  },
  methods: {
    // 返回上一页
    goAnalysis() {
      this.$router.go(-1)
    },
    // 获取头部信息
    async headSearch() {
      const { data: res } = await selectInformationDetail({ id: this.archiveId })
      if (res.resultCode === 200) {
        this.headDataFrom = res.data
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 获取列表信息
    async dataSearch() {
      const { data: res } = await selectInformationAnalysisPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.loading = false
        this.detailsTableData = res.data.list
        this.total = res.data.total
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 获取用户数信息
    async userNumSearch() {
      const { data: res } = await selectInformationAnalysisUserPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.userNumTableData = res.data.list
        this.userNumTotal = res.data.total
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 获取用户查看数信息
    async viewNumSearch() {
      const { data: res } = await selectInformationAnalysisUserViewPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.viewNumTableData = res.data.list
        this.viewNumTotal = res.data.total
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 获取用户分享数信息
    async shareNumSearch() {
      const { data: res } = await selectInformationAnalysisUserSharePage(this.queryInfo)
      if (res.resultCode === 200) {
        this.shareNumTableData = res.data.list
        this.shareNumTotal = res.data.total
      } else {
        this.$message.error('获取失败！')
      }
    },
    // 模糊查询
    changeInput(val) {
      this.queryInfo.pageNum = 1
      this.queryInfo.queryConditions = val
      this.dataSearch()
    },
    // 点击搜索按钮查询
    changeInputButton() {
      this.queryInfo.pageNum = 1
      this.dataSearch()
    },
    // 排序
    changeOrders(val) {
      switch (val.prop) {
        case 'correlation':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = "2"
            this.dataSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = "1"
            this.dataSearch()
          } else {
            this.queryInfo.orders = ''
            this.dataSearch()
          }
          break // 可选，用于防止代码自动执行到下一个case
        case 'userNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = "4"
            this.dataSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = "3"
            this.dataSearch()
          } else {
            this.queryInfo.orders = ''
            this.dataSearch()
          }
          break
        case 'viewNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = "6"
            this.dataSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = "5"
            this.dataSearch()
          } else {
            this.queryInfo.orders = ''
            this.dataSearch()
          }
          break
        case 'shareNum':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = "8"
            this.dataSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = "7"
            this.dataSearch()
          } else {
            this.queryInfo.orders = ''
            this.dataSearch()
          }
          break
        case 'pushTime':
          if (val.order == 'ascending') {
            //pushCompanyNum列升序时
            this.queryInfo.orders = "10"
            this.dataSearch()
          } else if (val.order == 'descending') {
            //pushCompanyNum列降序时
            this.queryInfo.orders = "9"
            this.dataSearch()
          } else {
            this.queryInfo.orders = ''
            this.dataSearch()
          }
          break
      }
    },
    // 打开用户数弹窗
    userNumDialog(row) {
      this.userNumDialogVisible = true
      this.userNumTitle = row.companyFullName
      this.queryInfo.companyId = row.id
      this.queryInfo.type = ''
      this.queryInfo.weekNum = ''
      this.queryInfo.time = ''
      this.queryInfo.typePage = ''
      this.queryInfo.weekNumPage = ''
      this.queryInfo.timePage = ''
      this.userNumSearch()
    },
    // 打开用户查看数弹窗
    viewNumDialog(row) {
      this.viewNumDialogVisible = true
      this.viewNumTitle = row.companyFullName
      this.queryInfo.companyId = row.id
      this.queryInfo.type = ''
      this.queryInfo.weekNum = ''
      this.queryInfo.time = ''
      this.queryInfo.typePage = ''
      this.queryInfo.weekNumPage = ''
      this.queryInfo.timePage = ''
      this.viewNumSearch()
    },
    // 打开用户分享数弹窗
    shareNumDialog(row) {
      this.shareNumDialogVisible = true
      this.shareNumTitle = row.companyFullName
      this.queryInfo.companyId = row.id
      this.queryInfo.type = ''
      this.queryInfo.weekNum = ''
      this.queryInfo.time = ''
      this.queryInfo.typePage = ''
      this.queryInfo.weekNumPage = ''
      this.queryInfo.timePage = ''
      this.shareNumSearch()
    },
    // 用户查看数排序
    changeViewNumOrders(val) {
      if (val.prop == 'checkTime') {
        if (val.order == 'ascending') {
          //pushCompanyNum列升序时
          this.queryInfo.orders = "2"
          this.viewNumSearch()
        } else if (val.order == 'descending') {
          //pushCompanyNum列降序时
          this.queryInfo.orders = "1"
          this.viewNumSearch()
        } else {
          this.queryInfo.orders = ''
          this.viewNumSearch()
        }
      }
    },
    // 用户分享数排序
    changeShareNumOrders(val) {
      if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //pushCompanyNum列升序时
          this.queryInfo.orders = "2"
          this.shareNumSearch()
        } else if (val.order == 'descending') {
          //pushCompanyNum列降序时
          this.queryInfo.orders = "1"
          this.shareNumSearch()
        } else {
          this.queryInfo.orders = ''
          this.shareNumSearch()
        }
      }
    },

    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.dataSearch()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.dataSearch()
    },
    //监听用户数pagesize变化
    userNumHandleSizeChange(newSize) {
      this.queryInfo.pageSizeSon = newSize
      this.userNumSearch()
    },
    //监听用户数页码变化
    userNumHandleCurrentChange(newCurrent) {
      this.queryInfo.pageNumSon = newCurrent
      this.userNumSearch()
    },
    //监听用户查看数pagesize变化
    viewNumHandleSizeChange(newSize) {
      this.queryInfo.pageSizeSon = newSize
      this.viewNumSearch()
    },
    //监听用户查看数页码变化
    viewNumHandleCurrentChange(newCurrent) {
      this.queryInfo.pageNumSon = newCurrent
      this.viewNumSearch()
    },
    //监听用户分享数pagesize变化
    shareNumHandleSizeChange(newSize) {
      this.queryInfo.pageSizeSon = newSize
      this.shareNumSearch()
    },
    //监听用户分享数页码变化
    shareNumHandleCurrentChange(newCurrent) {
      this.queryInfo.pageNumSon = newCurrent
      this.shareNumSearch()
    }
  }
}
</script>
  
<style lang="less" scoped>
.page_container {
  width: 100%;
  overflow: hidden;
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
    .content_title {
      height: 100px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .content_title_left {
        display: flex;
        width: 70%;
        height: 100%;
        align-items: center;
        .content_title_left_img {
          width: 171px;
          height: 117px;
          border: none;
          border-radius: 4px;
        }
        .content_title_left_content {
          height: 100%;
          margin-left: 40px;
          display: flex;
          flex-direction: column;
          .content_title_left_title {
            width: 700px;
            // height: 40px;
            padding: auto 0;
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: 550;
          }
          .content_title_left_body {
            // height: 60px;
            display: flex;
            flex-wrap: wrap;
            .content_title_left_label {
              // width: 120px;
              margin: 6px;
              color: #448aff;
              font-size: 14px;
            }
          }
        }
      }
    }
    .content_center {
      height: calc(100vh - 100px); /* 假设页面容器高度为100vh */
      margin-top: 10px;
      .center_search {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between; /* 两端对齐 */
        align-items: center;
        .search_right {
          width: 560px;
          .el-button {
            border: 1px solid #448aff;
            color: #fff;
            border-radius: 0;
            background-color: #448aff;
          }
        }
      }
      .center_table_img {
        height: 50px;
        width: 50px;
      }
      .center_table_label {
        display: flex;
        flex-wrap: wrap;
        .center_table_label_text {
          margin: 0 8px 8px 0;
          // color: #448aff;
        }
      }
    }
  }
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.custom-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 550;
}
/* 控制最左边的文本样式 */
.left-text {
  padding-left: 10px;
  border-left: 3px solid #448aff;
}

.center-text {
  /* 控制中间的文本样式，例如你可能需要让它居中对齐 */
  text-align: center;
  flex: 1;
}
// ::v-deep .el-dialog__header {
//   // padding: 10px;
//   font-size: 20px;
//   font-weight: 600;
// }
.office {
  color: #4e93fb;
  cursor: pointer;
}
</style>